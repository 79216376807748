// src/aiService.js

export const getAIResponse = async (prompt) => {
  try {
    const response = await fetch('http://localhost:5000/api/openai', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch AI response: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    return data.trim();
  } catch (error) {
    console.error('Error fetching AI response:', error);
    return 'Sorry, something went wrong. Please try again later.';
  }
};
