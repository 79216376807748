import React, { useState } from 'react';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';

const KYCUploadClientView = () => {
  const [file, setFile] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { clientId } = useParams(); // Get client ID from URL
  const db = getFirestore();
  const storage = getStorage();
  const navigate = useNavigate();

  const handleFileUpload = async () => {
    if (!file) {
      setError('Please select a file before uploading.');
      return;
    }

    try {
      setIsLoading(true);
      const storageRef = ref(storage, `kyc/${clientId}/${file.name}`);
      await uploadBytes(storageRef, file);

      // Update the client's status to 'kyc_complete'
      const clientRef = doc(db, 'clients', clientId);
      await updateDoc(clientRef, {
        status: 'kyc_complete',
      });

      setUploadSuccess(true);
      setError('');
      setTimeout(() => navigate('/dashboard'), 2000); // Redirect to dashboard after 2 seconds
    } catch (err) {
      setError('Failed to upload the document. Please try again.');
      console.error('Error uploading file:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>Upload KYC Documents</h2>
      <input
        type="file"
        onChange={(e) => {
          setFile(e.target.files[0]);
          setError(''); // Clear any existing errors
        }}
      />
      <button onClick={handleFileUpload} disabled={isLoading}>
        {isLoading ? 'Uploading...' : 'Upload'}
      </button>
      {uploadSuccess && <p>KYC document uploaded successfully! Redirecting...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default KYCUploadClientView;
