import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom'; // Import Link from React Router

const ClientManagement = () => {
  const [clients, setClients] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchClients = async () => {
      const clientsCollection = collection(db, 'clients');
      const clientsSnapshot = await getDocs(clientsCollection);
      const clientsList = clientsSnapshot.docs.map(doc => ({
        id: doc.id, // Capture the document ID
        ...doc.data(),
      }));
      setClients(clientsList);
    };

    fetchClients();
  }, [db]);

  return (
    <div>
      <h2>Client Management</h2>
      <ul>
        {clients.map((client) => (
          <li key={client.id}>
            {/* Link to the detailed profile view for each client */}
            <Link to={`/admin/client-management/${client.id}`}>{client.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClientManagement;
