import styled, { keyframes } from 'styled-components';

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: url('/images/background.jpg') no-repeat center center fixed;
  background-size: cover;
  padding: 20px;
  position: relative;
  z-index: 1;
  filter: grayscale(50%);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Off-black translucent overlay */
    z-index: -1;
  }
`;

export const LoginContainer = styled.div`
  background: rgba(0, 0, 0, 0.85);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
  max-width: 420px;
  width: 100%;
  text-align: justify;
  min-height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Keeps the title at the top */
`;

export const LogoContainer = styled.div`
  display; flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const Logo = styled.img`
  width: 40px; /* Adjust based on the logo dimensions */
  margin-bottom: 1.5rem; /* Space between logo and title */
  logo: url('/images/logo.png');
  margin: 0 auto 1.5rem auto;
  display: block;
  height: auto;
  cursor: pointer;
`;

export const LoginTitle = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  font-family: 'Lora', serif;
  text-align: center; /* Keeps the title centered */
  margin-bottom: 4rem; /* Adjust spacing below the title */
`;



export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: auto; /* Pushes the form down */
  margin-bottom: auto; /* Centers the form vertically */
`;



export const Input = styled.input`
  padding: 0.75rem 1rem;
  border-radius: 4px;
  border: none;
  background: none;
  color: #ffffff;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  width: 100%; /* Ensures the input fills the container */

  &:focus {
    outline: none;
    background: none;
  }

  &::placeholder {
    color: #888888;
  }
`;

export const ErrorMessage = styled.p`
  color: #ff6b6b;
  font-size: 0.9rem;
  margin-top: 1rem;
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimatedField = styled.div`
  animation: ${slideIn} 0.5s ease-in-out;
`;

export const HeaderLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
`;

export const HeaderLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem; /* Space between the links */
  text-align: center;

  a:not(.no-bullet):not(:last-child)::after {
    content: '•';
    margin: 0 1rem; /* Space around the bullet */
    color: #888;
  }

  /* Media query for narrow screens */
  @media (max-width: 600px) { /* Adjust the max-width as needed */
    flex-direction: column;
    gap: 0.5rem; /* Reduced gap when stacked */
    
    a:not(.no-bullet)::after {
      content: none; /* Remove bullet points when stacked */
    }

    a {
      margin: 0.5rem 0; /* Add vertical spacing between links */
    }
  }
`;


export const HeaderLink = styled.a`
  color: #ccc;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s;

  &:hover {
    color: #ddd;
  }

  &:not(:last-child)::after {
    content: '•';
    margin: 0 1rem; /* Space around the bullet */
    color: #888;
  }
  
  &.no-bullet::after {
    content: none;
  }
`;



export const CenteredHeaderLink = styled(HeaderLink)`
  display: block;
  text-align: center;
  margin: 1.5rem auto 0 auto;
  width: 100%; /* Ensures the link spans the width of the container for proper centering */
`;





export const FooterOverlay = styled.div`
  position: fixed;
  bottom: 1px;
  width: 100%;
  padding: 15px;
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
  text-align: center;
  font-size: 0.8rem;

  span {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
`;

export const FooterLink = styled.a`
  color: #ffffff;
  text-decoration: underline;
  &:hover {
    color: #ccc;
  }
`;

export const SecurityAdviceContainer = styled.div`
  max-height: 300px; /* Adjust as needed */
  overflow-y: auto;
  padding: 15px;
  background: none;
  border-radius: 8px;
  margin-bottom: 1rem;

  /* Custom Scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1); /* Subtle background track color */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5); /* Thumb color for the scrollbar */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.7); /* Lighter thumb color on hover */
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.1);
`;

export const SecurityAdviceText = styled.div`
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
  font-size: 1rem;

  ul {
    padding-left: 20px;
    list-style: disc;

    li {
      margin-bottom: 0.5rem;
    }
  }

  p {
    margin-bottom: 1rem;
  }
`;

export const NeedHelpContainer = styled.div`
  max-height: 322px; /* Adjust as needed */
  overflow-y: auto;
  padding: 15px;
  background: none;
  border-radius: 8px;
  margin-bottom: 1rem;

`;

export const NeedHelpText = styled.div`
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  text-align: center;

  ul {
    padding-left: 20px;
    list-style: disc;

    li {
      margin-bottom: 0.5rem;
    }
  }

  p {
    margin-bottom: 1rem;
  }
`;

export const ContactInfo = styled.span`
  color: #a40000; /* Dark red color */
  font-weight: bold;
  text-decoration: none; /* No underline for email */
  cursor: pointer;

  &:hover {
    color: #a52a2a; /* Slightly brighter dark red on hover */
  }
`;



export const LanguageContainer = styled.div`
  display: inline-block;
  position: relative;
  color: #ccc; /* Ensure the Change Language link has the same color as other HeaderLinks */
`;

export const LanguageDropdown = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  padding: 0.5rem 0;
  margin-top: 0.5rem;
  right: 0;
  z-index: 1000;
`;

export const LanguageOption = styled.div`
  padding: 0.5rem 1rem;
  color: #ccc;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;
