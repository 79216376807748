import React, { useState, useEffect } from 'react';
import { getAuth, confirmPasswordReset, isSignInWithEmailLink } from 'firebase/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import {
  LoginWrapper,
  LoginContainer,
  LogoContainer,
  Logo,
  LoginTitle,
  LoginForm,
  Input,
  ErrorMessage,
  AnimatedField,
  FooterOverlay,
  FooterLink,
} from '../../styles/secureLoginStyles';

const CreatePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();

  const clientId = searchParams.get('clientId');
  const oobCode = searchParams.get('oobCode');
  const email = window.localStorage.getItem('emailForSignIn');

  useEffect(() => {
    console.log('Client ID:', clientId); // Debugging log
    console.log('OOB Code:', oobCode); // Debugging log

    if (!isSignInWithEmailLink(auth, window.location.href)) {
      setError('Invalid or expired sign-up link.');
    }

    if (!clientId || !oobCode) {
      setError('Missing client information. Please request a new invitation.');
    }
  }, [auth, clientId, oobCode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      setIsLoading(true);

      if (!clientId || !oobCode) {
        throw new Error('Client ID or action code is missing.');
      }

      // Set the user's password using the oobCode
      await confirmPasswordReset(auth, oobCode, password);

      // Mark the client as registered in Firestore
      await updateDoc(doc(db, 'clients', clientId), {
        email: email,
        status: 'registered',
      });

      // Redirect to the KYC upload page
      navigate(`/onboarding/kyc-upload/${clientId}`);
    } catch (err) {
      console.error('Error creating password:', err);
      setError(`Failed to create password: ${err.message}. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginWrapper>
      <LoginContainer>
        <LogoContainer>
          <Logo src="/images/logo.png" alt="Logo" />
        </LogoContainer>
        <LoginTitle>Create Your Password</LoginTitle>
        <LoginForm onSubmit={handleSubmit}>
          <AnimatedField>
            <Input
              type="password"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </AnimatedField>
          <AnimatedField>
            <Input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </AnimatedField>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Setting Password...' : 'Set Password'}
          </button>
        </LoginForm>
      </LoginContainer>
      <FooterOverlay>
        Privacy Notice <FooterLink href="#">Learn More</FooterLink>
      </FooterOverlay>
    </LoginWrapper>
  );
};

export default CreatePassword;
