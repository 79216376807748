import styled from 'styled-components';

export const MainSection = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.85);
  padding: 3rem;
  color: #ffffff;

  @media (max-width: 1024px) {
    padding: 2rem;
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);  /* 2-column layout */
  grid-template-rows: auto;
  gap: 1.5rem;
  width: 100%;

  .grid-item {
    background: rgba(20, 20, 20, 0.9);
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);

    &-large {
      grid-column: span 2;  /* Span across both columns */
    }

    &-small {
      grid-column: span 1;
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;  /* 1-column layout for smaller screens */
    .grid-item-large {
      grid-column: span 1;  /* No spanning */
    }
  }
`;
