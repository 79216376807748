import React, { useState } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import {
  InviteContainer,
  InviteInput,
  InviteButton,
  SuccessMessage,
} from './InviteClient.styles';

const InviteClient = () => {
  const [clientEmail, setClientEmail] = useState('');
  const [invitationSent, setInvitationSent] = useState(false);
  const [error, setError] = useState('');
  const db = getFirestore();

  const handleInvite = async () => {
    setError(''); // Clear previous errors
    if (!clientEmail) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      // Create a new client record in Firestore with the UID
      const clientRef = await addDoc(collection(db, 'clients'), {
        email: clientEmail,
        uid: auth.currentUser.uid, // Store the UID of the current user (this is crucial for the rules)
        status: 'invited',
      });

      // Configure the action code settings for the sign-up link
      const actionCodeSettings = {
        url: `${window.location.origin}/onboarding/create-password?clientId=${clientRef.id}`, // Include the clientId
        handleCodeInApp: true,
      };

      // Send the sign-in link (acting as an invitation)
      await sendSignInLinkToEmail(auth, clientEmail, actionCodeSettings);

      // Store the email in local storage to complete the sign-in process later
      window.localStorage.setItem('emailForSignIn', clientEmail);

      setInvitationSent(true);
    } catch (error) {
      console.error('Error sending client invitation:', error);
      if (error.code === 'auth/invalid-email') {
        setError('Invalid email address.');
      } else if (error.code === 'auth/operation-not-allowed') {
        setError('Email/password sign-in is disabled in your Firebase project.');
      } else {
        setError('There was an issue sending the invitation. Please try again.');
      }
    }
  };

  return (
    <InviteContainer>
      <h2>Invite New Client</h2>
      <InviteInput
        type="email"
        placeholder="Client Email"
        value={clientEmail}
        onChange={(e) => setClientEmail(e.target.value)}
      />
      <InviteButton onClick={handleInvite}>Send Invitation</InviteButton>
      {invitationSent && <SuccessMessage>Invitation sent successfully!</SuccessMessage>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </InviteContainer>
  );
};

export default InviteClient;
