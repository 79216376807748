import styled from 'styled-components';

export const DashboardContainer = styled.div`
  padding: 2rem;
  background: rgba(0, 0, 0, 0.85); /* Subtle transparency for a refined look */
  color: #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  max-width: 1200px;
  margin: 2rem auto;
  font-family: 'Open Sans', sans-serif;
`;

export const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  h2 {
    font-family: 'Lora', serif;
    font-size: 2rem;
    font-weight: 700;
    color: #ffffff;
  }
`;

export const DashboardTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
  border-radius: 8px;
  overflow: hidden;
  background: rgba(20, 20, 20, 0.9); /* Deep background for contrast */

  th, td {
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    font-family: 'Open Sans', sans-serif;
  }

  th {
    background: rgba(30, 30, 30, 0.9);
    font-family: 'Lora', serif;
    font-size: 1.2rem;
    color: #ffffff;
    text-transform: uppercase;
  }

  td {
    font-size: 1rem;
    color: #d1d1d1;
  }

  tr:hover {
    background: rgba(255, 255, 255, 0.05); /* Subtle hover effect */
  }
`;

export const StatusTag = styled.span`
  background-color: ${({ status }) => {
    if (status === 'invited') return '#ffc107'; // Gold for invited
    if (status === 'pending_kyc') return '#17a2b8'; // Blue for pending KYC
    return '#28a745'; // Green for approved
  }};
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
`;

export const ActionButton = styled.button`
  background-color: #0069d9;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #004080;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const TableActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  gap: 1rem;

  button {
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    font-size: 1rem;
  }
`;
