import styled from 'styled-components';

export const AdminContainer = styled.div`
  padding: 2rem;
  background-color: #101010;
  min-height: 100vh;
  color: #e0e0e0;
  font-family: 'Open Sans', sans-serif;
`;

export const AdminHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid #333;
  margin-bottom: 2rem;
`;

export const AdminTitle = styled.h1`
  font-size: 2.5rem;
  font-family: 'Lora', serif;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
`;

export const AdminContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const DataEntryCard = styled.div`
  background: rgba(30, 30, 30, 0.95);
  border: 1px solid #333;
  padding: 2rem;
  border-radius: 8px;
  transition: background 0.3s;

  &:hover {
    background: rgba(40, 40, 40, 0.95);
  }

  h3 {
    font-size: 1.8rem;
    font-family: 'Lora', serif;
    margin-bottom: 1rem;
    color: #ffffff;
  }

  p {
    font-size: 1rem;
    color: #b0b0b0;
    line-height: 1.5;
  }
`;

export const DataEntryButton = styled.button`
  background: #b0b0b0;
  border: none;
  color: #000000;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 1rem;
  transition: background 0.3s;

  &:hover {
    background: #ffffff;
    color: #000;
  }
`;
