// src/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC79jLc43C6_Gj-lAi1KajjYenP9ZaHz28",
  authDomain: "ibxxsite.firebaseapp.com",
  projectId: "ibxxsite",
  storageBucket: "ibxxsite.appspot.com",
  messagingSenderId: "838018525529",
  appId: "1:838018525529:web:48f843d6e010281dda0448",
  measurementId: "G-NNQJ1L44MJ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
export const storage = getStorage(app);

const db = getFirestore(app);

export { auth, db };