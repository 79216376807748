import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.85);
  padding: 1.5rem 3rem;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const Logo = styled.div`
  display: inline-flex; /* Use inline-flex to align with other elements in the header */
  align-items: center; /* Vertically align the image and text */
  
  img {
    height: 40px;
    width: auto;
    margin-right: 10px; /* Space between logo and IB text */
  }

  @media (max-width: 768px) {
    img {
      height: 30px;
    }

    h1 {
      font-size: 1.5rem;
    }
  }
`;




// Ensure the NavLink is correctly styled as a Link with all the original styles
export const NavLink = styled(RouterLink)`
  color: #ffffff; /* White color */
  text-decoration: none; /* No default underline */
  font-size: 1.1rem;
  font-family: 'Lora', serif; /* Specific font */
  padding-bottom: 0.3rem;
  position: relative;
  transition: color 0.3s;

  &:hover {
    color: #ddd;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0;
    background: #fff;
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }

  &.active {
    color: #fff;
    font-weight: bold;
  }

  &.active::after {
    width: 100%;
    background: #fff;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    width: 100%;
    &::after {
      display: none;
    }
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    gap: 1rem;
    align-items: center;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.8rem;
  cursor: pointer;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.95);
  border-top: 1px solid #b0b0b0;
  padding: 2rem 3rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  z-index: 10;

  ${NavLink} {
    margin: 0;
    color: #ccc;
    text-decoration: none;
    font-size: 1.1rem;
    font-family: 'Lora', serif;
    transition: color 0.3s;

    &:hover {
      color: #ddd;
    }

    &.active {
      color: #fff;
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem 2rem;
  }
`;

export const SubMenu = styled.div`
  position: relative;
  cursor: pointer;

  &:hover > div {
    display: block;
  }
`;

export const SubMenuItem = styled.span`
  color: #ffffff;
  font-family: 'Lora', serif;
  font-size: 1.1rem;
  padding: 0.5rem;
  display: block;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #ddd;
  }
`;

export const SubMenuDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.95);
  border: 1px solid #b0b0b0;
  padding: 1rem;
  display: none;
  flex-direction: column;
  z-index: 100;

  ${NavLink} {
    font-size: 1rem;
    margin: 0.5rem 0;
  }

  @media (max-width: 768px) {
    left: auto;
    right: 0;
  }
`;

export const LanguageSelect = styled.div`
  position: relative;
  background: none;
  text-align: center;
  border: 1px solid #b0b0b0;
  color: #ffffff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-family: 'Lora', serif;
  cursor: pointer;
  margin-left: 1.5rem;
  transition: background 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 1024px) {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 0.5rem 0.5rem;
    font-size: 0.9rem;
  }
`;

export const TimeZoneBar = styled.div`
  background: rgba(0, 0, 0, 0.9);
  padding: 0.5rem 3rem;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;

export const TimeZonesContainer = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;

    > div {
      display: none;
    }

    > div:nth-child(-n+3) {
      display: block;
    }
  }
`;

export const TimeZone = styled.div`
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9rem;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const TickerContainer = styled.div`
  background: rgba(20, 20, 20, 0.9);
  padding: 0.5rem 3rem;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

export const TickerItem = styled.div`
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #ffffff;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

export const LanguageDropdown = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 0;
  background: rgba(20, 20, 20, 0.95);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  padding: 0.5rem 0;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  min-width: 150px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LanguageOption = styled.div`
  padding: 0.5rem 1rem;
  color: #b0b0b0;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

export const AccountDropdown = styled(DropdownMenu)`
  /* Same styling as DropdownMenu */
`;

export const AccountOption = styled.a`
  margin: 0 1.5rem;
  color: #ccc;
  text-decoration: none;
  font-size: 1.1rem;
  font-family: 'Lora', serif;
  transition: color 0.3s;

  &:hover {
    color: #ddd;
  }

  &.active {
    color: #fff;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    margin: 1rem 0;
    font-size: 1rem;
  }
`;

export const FAB = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #a40000;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #870000;
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }

  @media (max-width: 768px) {
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }
`;

export const AssistantModal = styled.div`
  position: fixed;
  bottom: 80px;
  right: 20px;
  background: rgba(0, 0, 0, 0.85);
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1001;

  h2 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #ffffff;
  }

  form {
    display: flex;
    justify-content: space-between;
  }
`;
