import styled from 'styled-components';

export const ProfileContainer = styled.div`
  padding: 2rem;
  background-color: #121212;
  color: #ffffff;
`;

export const ProfileHeader = styled.div`
  margin-bottom: 2rem;
  h2 {
    font-family: 'Lora', serif;
    font-size: 2rem;
    color: #ffffff;
  }
`;

export const ProfileInfo = styled.div`
  display: flex;
  gap: 2rem;
`;

export const ProfileSection = styled.div`
  flex: 1;
  h3 {
    font-family: 'Lora', serif;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const ProfileDetails = styled.div`
  p {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
`;

export const DocumentList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const DocumentItem = styled.li`
  margin-bottom: 1rem;
  a {
    color: #a40000;
    text-decoration: none;
    font-size: 1.1rem;

    &:hover {
      text-decoration: underline;
    }
  }
`;
