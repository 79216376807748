import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import {
  ProfileContainer,
  ProfileSection,
  ProfileHeader,
  ProfileInfo,
  ProfileDetails,
  DocumentList,
  DocumentItem,
} from './ClientProfile.styles';

const ClientProfileAdminView = () => {
  const { clientId } = useParams();
  const [clientData, setClientData] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    const fetchClientData = async () => {
      const clientDoc = await getDoc(doc(db, 'clients', clientId));
      if (clientDoc.exists()) {
        setClientData(clientDoc.data());
      } else {
        console.error('Client not found.');
      }
    };

    fetchClientData();
  }, [clientId, db]);

  if (!clientData) {
    return <div>Loading...</div>;
  }

  return (
    <ProfileContainer>
      <ProfileHeader>
        <h2>{clientData.name}</h2>
      </ProfileHeader>
      <ProfileInfo>
        <ProfileSection>
          <h3>Personal Information</h3>
          <ProfileDetails>
            <p><strong>Email:</strong> {clientData.email || 'N/A'}</p>
            <p><strong>Phone:</strong> {clientData.phone || 'N/A'}</p>
            <p><strong>Risk Profile:</strong> {clientData.riskProfile || 'N/A'}</p>
          </ProfileDetails>
        </ProfileSection>
        <ProfileSection>
          <h3>Investment Preferences</h3>
          <ProfileDetails>
            <p><strong>Risk Tolerance:</strong> {clientData.riskTolerance || 'N/A'}</p>
            <p><strong>Preferred Assets:</strong> {(clientData.preferredAssets && clientData.preferredAssets.length > 0) ? clientData.preferredAssets.join(', ') : 'N/A'}</p>
            <p><strong>Exclusions:</strong> {clientData.exclusions || 'N/A'}</p>
          </ProfileDetails>
        </ProfileSection>
      </ProfileInfo>
      <ProfileSection>
        <h3>Documents</h3>
        <DocumentList>
          {clientData.documents && clientData.documents.length > 0 ? (
            clientData.documents.map((doc, index) => (
              <DocumentItem key={index}>
                <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.name}</a>
              </DocumentItem>
            ))
          ) : (
            <p>No documents available.</p>
          )}
        </DocumentList>
      </ProfileSection>
    </ProfileContainer>
  );
};

export default ClientProfileAdminView;
