import React, { useState, useEffect } from 'react';
import { NavLink } from './Header.styles'; // Import the styled NavLink
import {
  StyledHeader,
  Nav,
  Logo,
  LanguageSelect,
  MenuButton,
  DropdownMenu,
  HeaderRight,
  LanguageDropdown,
  LanguageOption,
  AccountDropdown,
  AccountOption,
  TimeZonesContainer,
  TimeZoneBar,
  TimeZone,
  FAB,
  TickerContainer,
  TickerItem,
} from './Header.styles';
import { auth } from '../../firebaseConfig';
import AIRealTimeAssistant from '../AIRealTimeAssistant';

const Header = ({ onLogout }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [currentTimes, setCurrentTimes] = useState({});
  const [isAssistantOpen, setIsAssistantOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('EN');

  const cities = [
    { name: "New York", timeZone: "America/New_York" },
    { name: "London", timeZone: "Europe/London" },
    { name: "Geneva", timeZone: "Europe/Zurich" },
    { name: "Zurich", timeZone: "Europe/Zurich" },
    { name: "Dubai", timeZone: "Asia/Dubai" },
    { name: "Hong Kong", timeZone: "Asia/Hong_Kong" },
    { name: "Tokyo", timeZone: "Asia/Tokyo" },
  ];

  useEffect(() => {
    const updateTime = () => {
      const times = {};
      cities.forEach(city => {
        times[city.name] = new Intl.DateTimeFormat('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZone: city.timeZone
        }).format(new Date());
      });
      setCurrentTimes(times);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleLanguageMenu = () => setLanguageMenuOpen(!languageMenuOpen);
  const toggleAccountMenu = () => setAccountMenuOpen(!accountMenuOpen);
  const toggleAIAssistant = () => setIsAssistantOpen(!isAssistantOpen);

  const handleLogout = () => {
    auth.signOut()
      .then(() => onLogout())
      .catch((error) => console.error('Error during logout:', error));
  };

  const handleLanguageChange = (languageCode) => {
    setSelectedLanguage(languageCode);
    setLanguageMenuOpen(false);
  };

  return (
    <>
      <StyledHeader>
        <Logo>
          <NavLink to="/overview">
            <img src="/images/logo-ai.png" alt="Logo" />
          </NavLink>
        </Logo>
        <HeaderRight>
          <Nav>
            <NavLink to="/trusts">Estate & Trusts</NavLink>
            <NavLink to="/tax">Tax Planning</NavLink>
            <NavLink to="/wealth">Wealth Management</NavLink>
            <NavLink to="/mail">Mail</NavLink>
            <NavLink to="/financials">Finance</NavLink>
            <NavLink to="/documents">Drive</NavLink>
            <NavLink to="#" onClick={toggleAccountMenu}>Account</NavLink>
            {accountMenuOpen && (
              <AccountDropdown>
                 <NavLink to="/profile">Profile</NavLink> {/* Use NavLink here */}
                <NavLink to="#">Settings</NavLink>
                <NavLink to="#">Notifications</NavLink>
                <NavLink to="#" onClick={handleLogout}>Logout</NavLink>
              </AccountDropdown>
            )}
          </Nav>
          <LanguageSelect onClick={toggleLanguageMenu}>
            {languageMenuOpen ? (
              <LanguageDropdown>
                <LanguageOption onClick={() => handleLanguageChange('EN')}>English</LanguageOption>
                <LanguageOption onClick={() => handleLanguageChange('FR')}>Français</LanguageOption>
                <LanguageOption onClick={() => handleLanguageChange('DE')}>Deutsch</LanguageOption>
                <LanguageOption onClick={() => handleLanguageChange('ES')}>Español</LanguageOption>
              </LanguageDropdown>
            ) : (
              selectedLanguage
            )}
          </LanguageSelect>
          <MenuButton onClick={toggleMenu}>☰</MenuButton>
        </HeaderRight>
        {menuOpen && (
          <DropdownMenu>
            <NavLink to="/trusts">Estate & Trusts</NavLink>
            <NavLink to="/tax">Tax Planning</NavLink>
            <NavLink to="/wealth">Wealth Management</NavLink>
            <NavLink to="/mail">Mail</NavLink>
            <NavLink to="/financials">Finance</NavLink>
            <NavLink to="/documents">Drive</NavLink>
            <NavLink to="#" onClick={toggleAccountMenu}>Account</NavLink>
            {accountMenuOpen && (
              <AccountDropdown>
                 <NavLink to="/profile">Profile</NavLink> {/* Use NavLink here */}
                <NavLink to="#">Settings</NavLink>
                <NavLink to="#">Notifications</NavLink>
                <NavLink to="#" onClick={handleLogout}>Logout</NavLink>
              </AccountDropdown>
            )}
          </DropdownMenu>
        )}
      </StyledHeader>
      <TimeZoneBar>
        <TimeZonesContainer>
          {cities.map(city => (
            <TimeZone key={city.name}>
              {city.name}: {currentTimes[city.name]}
            </TimeZone>
          ))}
        </TimeZonesContainer>
      </TimeZoneBar>
      <TickerContainer>
        <TickerItem>Stock A: 100.23 +1.45%</TickerItem>
        <TickerItem>Index B: 3245.67 -0.25%</TickerItem>
        {/* Add more ticker items */}
      </TickerContainer>
      <FAB onClick={toggleAIAssistant}>AI</FAB>
      {isAssistantOpen && <AIRealTimeAssistant />}
    </>
  );
};

export default Header;
