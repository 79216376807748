import React from 'react';

const AnalyticsEntry = () => {
  return (
    <div>
      <h2>Analytics Data Entry</h2>
      {/* Form to input analytics data */}
      <form>
        <label>
          Data Point:
          <input type="text" name="dataPoint" />
        </label>
        <br />
        <label>
          Value:
          <input type="number" name="value" />
        </label>
        <br />
        <button type="submit">Save Data</button>
      </form>
    </div>
  );
};

export default AnalyticsEntry;
