import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Firestore imports
import SecurityAdvice from './sections/SecurityAdvice';
import NeedHelp from './sections/Shop';
import ChangeLanguage from './sections/ChangeLanguage';
import en from './translations/en.json';
import fr from './translations/fr.json';
import de from './translations/de.json';
import it from './translations/it.json';
import es from './translations/es.json';
import jp from './translations/jp.json';
import ru from './translations/ru.json';
import {
  LoginWrapper,
  LoginContainer,
  LogoContainer,
  Logo,
  LoginTitle,
  LoginForm,
  Input,
  ErrorMessage,
  AnimatedField,
  FooterOverlay,
  FooterLink,
  HeaderLinksContainer,
  HeaderLinks,
  HeaderLink,
  CenteredHeaderLink,
} from '../../styles/secureLoginStyles';

const SecureLogin = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isPasswordStep, setIsPasswordStep] = useState(false);
  const [view, setView] = useState('login');
  const [lang, setLang] = useState(en);

  const db = getFirestore(); // Initialize Firestore

  const handleLanguageChange = (code) => {
    switch (code) {
      case 'fr':
        setLang(fr);
        break;
      case 'de':
        setLang(de);
        break;
      case 'it':
        setLang(it);
        break;
      case 'es':
        setLang(es);
        break;
      case 'jp':
        setLang(jp);
        break;
      case 'ru':
        setLang(ru);
        break;
      default:
        setLang(en);
    }
  };

  const handleUsernameSubmit = (e) => {
    e.preventDefault();
    if (!isPasswordStep) {
      setIsPasswordStep(true);
    } else {
      handlePasswordSubmit(e);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Fetch the user's role from Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const role = userDoc.data().role;
        onLogin(user, role); // Pass both user and role to the onLogin handler
      } else {
        setError('No role found for this user.');
      }
    } catch (err) {
      setError('Invalid login credentials');
    }
  };

  const renderContent = () => {
    switch (view) {
      case 'security':
        return (
          <>
            <LoginTitle>{lang.securityAdviceTitle}</LoginTitle>
            <SecurityAdvice />
            <CenteredHeaderLink onClick={() => setView('login')} style={{ marginTop: '1.5rem', textAlign: 'center' }}>{lang.backToPreviousPage}</CenteredHeaderLink>
          </>
        );
      case 'help':
        return (
          <>
            <LoginTitle>{lang.needHelpTitle}</LoginTitle>
            <NeedHelp />
            <CenteredHeaderLink onClick={() => setView('login')} style={{ marginTop: '1.5rem', textAlign: 'center' }}>{lang.backToPreviousPage}</CenteredHeaderLink>
          </>
        );
      case 'language':
        return (
          <>
            <LoginTitle>{lang.changeLanguageTitle}</LoginTitle>
            <ChangeLanguage onLanguageChange={handleLanguageChange} currentLanguage={lang.language} />
            <CenteredHeaderLink onClick={() => setView('login')} style={{ marginTop: '1.5rem', textAlign: 'center' }}>{lang.backToPreviousPage}</CenteredHeaderLink>
          </>
        );
      default:
        return (
          <>
            <LoginTitle>{isPasswordStep ? `${lang.welcomeMessage}${email}` : lang.loginTitle}</LoginTitle>
            <LoginForm onSubmit={handleUsernameSubmit}>
              <AnimatedField>
                <Input
                  type={isPasswordStep ? 'password' : 'email'}
                  placeholder={isPasswordStep ? lang.passwordPlaceholder : lang.usernamePlaceholder}
                  value={isPasswordStep ? password : email}
                  onChange={(e) => (isPasswordStep ? setPassword(e.target.value) : setEmail(e.target.value))}
                  required
                  autoFocus
                />
              </AnimatedField>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </LoginForm>
          </>
        );
    }
  };

  return (
    <LoginWrapper>
      <LoginContainer>
        <LogoContainer>
          <Logo src="/images/logo.png" alt="Logo" onClick={() => setView('login')} />
        </LogoContainer>
        <AnimatedField>{renderContent()}</AnimatedField>
      </LoginContainer>
      <HeaderLinksContainer>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
    {/* First Row of Header Links */}
    <HeaderLinks>
      <HeaderLink onClick={() => setView('security')}>{lang.securityAdviceTitle}</HeaderLink>
      <HeaderLink onClick={() => setView('help')}>{lang.needHelpTitle}</HeaderLink>
      <ChangeLanguage onLanguageChange={handleLanguageChange} currentLanguage={lang.language} className="no-bullet" />
    </HeaderLinks>

    {/* Second Row of Header Links */}
    <HeaderLinks>
      <HeaderLink href="#">Art</HeaderLink>
      <HeaderLink href="#">Film</HeaderLink>
      <HeaderLink href="#">Literature</HeaderLink>
      <HeaderLink href="#">Music</HeaderLink>
    </HeaderLinks>
  </div>
</HeaderLinksContainer>
      <FooterOverlay>
  {/* Privacy Notice */}
  {lang.privacyNotice} <FooterLink href="#">{lang.privacyNoticeLink}</FooterLink>

  {/* Additional Rows */}
  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
    {/* Left Side: Copyright */}
    <div>
      &copy; 2024 Ian Bloom. All rights reserved.
    </div>
    
    {/* Right Side: Dummy Links */}
    <div style={{ display: 'flex', gap: '1.5rem' }}>
      <FooterLink href="#">About Ian Bloom</FooterLink>
      <FooterLink href="#">Legal</FooterLink>
      <FooterLink href="#">Cookie Policy</FooterLink>
      <FooterLink href="#">Website Accessibility</FooterLink>
    </div>
  </div>
</FooterOverlay>

    </LoginWrapper>
  );
};

export default SecureLogin;
