// /src/admin/ClientManagement/AddClient.js
import React, { useState } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

const AddClient = () => {
  const [clientData, setClientData] = useState({
    name: '',
    email: '',
    phone: '',
    address: ''
  });
  const db = getFirestore();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleAddClient = async () => {
    try {
      await addDoc(collection(db, 'clients'), clientData);
      alert('Client added successfully');
    } catch (error) {
      console.error('Error adding client:', error);
    }
  };

  return (
    <div>
      <h2>Add Client</h2>
      <input
        type="text"
        name="name"
        placeholder="Client Name"
        value={clientData.name}
        onChange={handleChange}
      />
      <input
        type="email"
        name="email"
        placeholder="Client Email"
        value={clientData.email}
        onChange={handleChange}
      />
      <input
        type="text"
        name="phone"
        placeholder="Client Phone"
        value={clientData.phone}
        onChange={handleChange}
      />
      <input
        type="text"
        name="address"
        placeholder="Client Address"
        value={clientData.address}
        onChange={handleChange}
      />
      <button onClick={handleAddClient}>Add Client</button>
    </div>
  );
};

export default AddClient;
