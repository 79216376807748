// /src/admin/ClientOnboarding/KYCUpload.js
import React, { useState } from 'react';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { storage } from '../../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  UploadContainer,
  UploadInput,
  UploadButton,
} from './KYCUpload.styles';

const KYCUpload = ({ clientId }) => {
  const [file, setFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const db = getFirestore();

  const handleFileUpload = async () => {
    if (file) {
      try {
        const storageRef = ref(storage, `kyc/${clientId}/${file.name}`);
        const uploadResult = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(uploadResult.ref);

        // Update Firestore with the KYC document URL and status
        const clientRef = doc(db, 'clients', clientId);
        await updateDoc(clientRef, {
          status: 'kyc_complete',
          documents: [...clientRef.data().documents, { name: file.name, url: downloadURL }],
        });

        setUploadMessage('KYC document uploaded successfully.');
      } catch (error) {
        setUploadMessage('Error uploading document.');
        console.error('Error uploading KYC document:', error);
      }
    }
  };

  return (
    <UploadContainer>
      <h2>Upload KYC Documents</h2>
      <UploadInput
        type="file"
        onChange={(e) => setFile(e.target.files[0])}
      />
      <UploadButton onClick={handleFileUpload}>Upload</UploadButton>
      {uploadMessage && <p>{uploadMessage}</p>}
    </UploadContainer>
  );
};

export default KYCUpload;
