import React from 'react';
import { NeedHelpContainer, NeedHelpText, ContactInfo } from '../../../styles/secureLoginStyles';



const NeedHelp = () => {
  return (
    <NeedHelpContainer>
        <NeedHelpText>
      <p>Should you require more assistance, please contact our Client Call Centre, Monday to Friday from 8:00am to 7:00pm (New York time), to receive assistance by phone on:</p>
      <p><ContactInfo>+41 58 323 4567</ContactInfo></p>
      <p>or by email at:</p>
      <p><ContactInfo as="a" href="mailto:clientcallcenter@ibxx.ch">clientcallcenter@ibxx.ch</ContactInfo></p>      </NeedHelpText>
    </NeedHelpContainer>
  );
};

export default NeedHelp;
