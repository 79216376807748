// /src/admin/ClientOnboarding/OnboardingDashboard.js
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import {
  DashboardContainer,
  DashboardTable,
  StatusTag,
  ActionButton,
} from './OnboardingDashboard.styles';

const OnboardingDashboard = () => {
  const [clients, setClients] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchClients = async () => {
      const clientsCollection = collection(db, 'clients');
      const clientsSnapshot = await getDocs(clientsCollection);
      const clientsList = clientsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClients(clientsList);
    };

    fetchClients();
  }, [db]);

  const handleResendInvitation = async (clientId) => {
    // Logic to resend invitation email
    console.log(`Resending invitation to client ID: ${clientId}`);
  };

  const handleViewKYC = (clientId) => {
    // Logic to view KYC documents, can redirect to KYCUpload
    console.log(`Viewing KYC for client ID: ${clientId}`);
  };

  return (
    <DashboardContainer>
      <h2>Client Onboarding Dashboard</h2>
      <DashboardTable>
        <thead>
          <tr>
            <th>Email</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client.id}>
              <td>{client.email}</td>
              <td>
                <StatusTag status={client.status}>{client.status}</StatusTag>
              </td>
              <td>
                {client.status === 'invited' && (
                  <ActionButton onClick={() => handleResendInvitation(client.id)}>
                    Resend Invitation
                  </ActionButton>
                )}
                {client.status === 'pending_kyc' && (
                  <ActionButton onClick={() => handleViewKYC(client.id)}>
                    View KYC Documents
                  </ActionButton>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </DashboardTable>
    </DashboardContainer>
  );
};

export default OnboardingDashboard;
