import styled, { createGlobalStyle } from 'styled-components';
import Lora from '../assets/fonts/Lora-Regular.ttf';
import OpenSans from '../assets/fonts/OpenSans-Regular.ttf';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Lora';
    src: url(${Lora}) format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSans}) format('truetype');
  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    color: #e0e0e0;
    background: #000;
    overflow-x: hidden; /* Prevent horizontal scroll */
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #000;
  overflow: hidden; /* Prevent overflow */
  color: #e0e0e0;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(20, 20, 20, 0.95);
  padding: 1.5rem 3rem;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;

  h1 {
    margin: 0;
    font-size: 2rem;
    font-weight: 700;
    font-family: 'Lora', serif;
    color: #ffffff;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    justify-content: flex-end;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavLink = styled.a`
  color: #b0b0b0;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s;

  &:hover {
    color: #ffffff;
  }
`;

export const LanguageSelect = styled.select`
  background: none;
  border: 1px solid #b0b0b0;
  color: #b0b0b0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  appearance: none;
  cursor: pointer;

  option {
    background: #333;
    color: #ffffff;
  }
`;

export const LoginButton = styled.a`
  background: none;
  border: 1px solid #b0b0b0;
  color: #b0b0b0;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: #b0b0b0;
    color: #000000;
  }
`;

export const LogoutButton = styled.button`
  background: none;
  border: 1px solid #b0b0b0;
  color: #b0b0b0;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  margin-left: 1rem;

  &:hover {
    background: #b0b0b0;
    color: #000000;
  }

  @media (max-width: 768px) {
    margin-left: 0.5rem;
  }
`;


export const MenuButton = styled.button`
  background: none;
  border: none;
  color: #b0b0b0;
  font-size: 1.8rem;
  cursor: pointer;

  @media (min-width: 769px) {
    display: none;
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 4.5rem;
  right: 3rem;
  background: rgba(20, 20, 20, 0.95);
  border: 1px solid #b0b0b0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  ${NavLink} {
    margin: 0;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

export const MainSection = styled.main`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.85);
  background-attachment: fixed;
  padding: 3rem;
  text-align: center;

  h2 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-family: 'Lora', serif;
    color: #ffffff;
  }

  p {
    font-size: 1.2rem;
    max-width: 700px;
    color: #b0b0b0;
  }
`;

