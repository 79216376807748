// /src/components/ClientProfile/ClientProfile.styles.js
import styled from 'styled-components';

export const ProfileContainer = styled.div`
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 600px;
  margin: auto;
`;

export const ProfileDetails = styled.div`
  h2 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0.5rem;
  }
`;

export const EditProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputField = styled.input`
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const SaveButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  margin-right: 1rem;
`;

export const CancelButton = styled.button`
  background-color: #f44336;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
`;

export const EditButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
`;
