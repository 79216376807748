import styled, { createGlobalStyle } from 'styled-components';

export const Footer = styled.footer`
  background: rgba(20, 20, 20, 0.95);
  padding: 1.5rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1rem;
  font-weight: 300;
  color: #b0b0b0;

  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const FooterLeft = styled.div`
  flex: 1;
`;

export const FooterRight = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-right: 80px;
`;

export const FooterLink = styled.a`
  color: #b0b0b0;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;

  &:hover {
    color: #ffffff;
  }
`;


