import React, { useState } from 'react';
import { NavLink } from '../Header/Header.styles';
import {
  StyledHeader,
  Nav,
  Logo,
  MenuButton,
  DropdownMenu,
  HeaderRight,
  AccountDropdown,
  AccountOption,
  LanguageSelect,
  LanguageDropdown,
  LanguageOption,
  SubMenu,
  SubMenuItem,
  FAB,
} from '../Header/Header.styles';
import { auth } from '../../firebaseConfig';
import AIRealTimeAssistant from '../AIRealTimeAssistant';

const AdminHeader = ({ onLogout }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [clientManagementOpen, setClientManagementOpen] = useState(false);
  const [onboardingOpen, setOnboardingOpen] = useState(false);
  const [isAssistantOpen, setIsAssistantOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('EN');

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleLanguageMenu = () => setLanguageMenuOpen(!languageMenuOpen);
  const toggleAccountMenu = () => setAccountMenuOpen(!accountMenuOpen);
  const toggleClientManagement = () => setClientManagementOpen(!clientManagementOpen);
  const toggleOnboarding = () => setOnboardingOpen(!onboardingOpen);
  const toggleAIAssistant = () => setIsAssistantOpen(!isAssistantOpen);

  const handleLogout = () => {
    auth.signOut()
      .then(() => onLogout())
      .catch((error) => console.error('Error during logout:', error));
  };

  const handleLanguageChange = (languageCode) => {
    setSelectedLanguage(languageCode);
    setLanguageMenuOpen(false);
  };

  return (
    <>
      <StyledHeader>
        <Logo>
          <NavLink to="/admin/dashboard">
            <img src="/images/logo-ai.png" alt="Logo" />
          </NavLink>
        </Logo>
        <HeaderRight>
          <Nav>
            <NavLink to="/admin/dashboard">Dashboard</NavLink>
            <SubMenu>
              <SubMenuItem onClick={toggleClientManagement}>
                Client Management
              </SubMenuItem>
              {clientManagementOpen && (
                <DropdownMenu>
                  <NavLink to="/admin/client-management">Manage Clients</NavLink>
                  <NavLink to="/admin/client-management/add">Add Client</NavLink>
                </DropdownMenu>
              )}
            </SubMenu>
            <SubMenu>
              <SubMenuItem onClick={toggleOnboarding}>
                Onboarding
              </SubMenuItem>
              {onboardingOpen && (
                <DropdownMenu>
                  <NavLink to="/admin/onboarding">Onboarding Dashboard</NavLink>
                  <NavLink to="/admin/onboarding/invite">Invite Client</NavLink>
                </DropdownMenu>
              )}
            </SubMenu>
            <NavLink to="/admin/analytics-entry">Analytics Entry</NavLink>
            <NavLink to="/admin/reports">Reports</NavLink>
            <NavLink to="#" onClick={toggleAccountMenu}>Account</NavLink>
            {accountMenuOpen && (
              <AccountDropdown>
                <AccountOption href="#">Profile</AccountOption>
                <AccountOption href="#">Settings</AccountOption>
                <AccountOption href="#">Notifications</AccountOption>
                <AccountOption href="#" onClick={handleLogout}>Logout</AccountOption>
              </AccountDropdown>
            )}
          </Nav>
          <LanguageSelect onClick={toggleLanguageMenu}>
            {languageMenuOpen ? (
              <LanguageDropdown>
                <LanguageOption onClick={() => handleLanguageChange('EN')}>English</LanguageOption>
                <LanguageOption onClick={() => handleLanguageChange('FR')}>Français</LanguageOption>
                <LanguageOption onClick={() => handleLanguageChange('DE')}>Deutsch</LanguageOption>
                <LanguageOption onClick={() => handleLanguageChange('ES')}>Español</LanguageOption>
              </LanguageDropdown>
            ) : (
              selectedLanguage
            )}
          </LanguageSelect>
          <MenuButton onClick={toggleMenu}>☰</MenuButton>
        </HeaderRight>
        {menuOpen && (
          <DropdownMenu>
            <NavLink to="/admin/dashboard">Dashboard</NavLink>
            <SubMenu>
              <SubMenuItem onClick={toggleClientManagement}>
                Client Management
              </SubMenuItem>
              {clientManagementOpen && (
                <DropdownMenu>
                  <NavLink to="/admin/client-management">Manage Clients</NavLink>
                  <NavLink to="/admin/client-management/add">Add Client</NavLink>
                </DropdownMenu>
              )}
            </SubMenu>
            <SubMenu>
              <SubMenuItem onClick={toggleOnboarding}>
                Onboarding
              </SubMenuItem>
              {onboardingOpen && (
                <DropdownMenu>
                  <NavLink to="/admin/onboarding">Onboarding Dashboard</NavLink>
                  <NavLink to="/admin/onboarding/invite">Invite Client</NavLink>
                </DropdownMenu>
              )}
            </SubMenu>
            <NavLink to="/admin/analytics-entry">Analytics Entry</NavLink>
            <NavLink to="/admin/reports">Reports</NavLink>
            <NavLink to="#" onClick={toggleAccountMenu}>Account</NavLink>
            {accountMenuOpen && (
              <AccountDropdown>
                <AccountOption href="#">Profile</AccountOption>
                <AccountOption href="#">Settings</AccountOption>
                <AccountOption href="#">Notifications</AccountOption>
                <AccountOption href="#" onClick={handleLogout}>Logout</AccountOption>
              </AccountDropdown>
            )}
          </DropdownMenu>
        )}
      </StyledHeader>
      <FAB onClick={toggleAIAssistant}>AI</FAB>
      {isAssistantOpen && <AIRealTimeAssistant />}
    </>
  );
};

export default AdminHeader;
