import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminContainer, AdminHeader, AdminTitle, AdminContent, DataEntryCard, DataEntryButton } from './AdminDashboard.styles';

const AdminDashboard = () => {
  const navigate = useNavigate();

  return (
    <AdminContainer>
      <AdminHeader>
        <AdminTitle>Admin Dashboard</AdminTitle>
      </AdminHeader>
      <AdminContent>
        {/* Data Entry Cards */}
        <DataEntryCard>
          <h3>Analytics Data Entry</h3>
          <p>Manage and input key analytics data used for tracking performance, forecasting, and generating reports.</p>
          <DataEntryButton onClick={() => navigate('/admin/analytics-entry')}>Enter Data</DataEntryButton>
        </DataEntryCard>
        <DataEntryCard>
          <h3>Client Management</h3>
          <p>View and manage client information, portfolios, and account settings from this section.</p>
          <DataEntryButton onClick={() => navigate('/admin/client-management')}>Manage Clients</DataEntryButton>
        </DataEntryCard>
        <DataEntryCard>
          <h3>Reporting & Analytics</h3>
          <p>Generate comprehensive financial reports and analyze performance metrics across portfolios.</p>
          <DataEntryButton onClick={() => navigate('/admin/reports')}>View Reports</DataEntryButton>
        </DataEntryCard>
      </AdminContent>
    </AdminContainer>
  );
};

export default AdminDashboard;
