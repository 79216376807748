import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailLink } from 'firebase/auth';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';

const OnboardingPage = () => {
  const { clientId } = useParams();
  const [password, setPassword] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const db = getFirestore();
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const completeSignIn = async () => {
      try {
        const email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          throw new Error('Email not found. Please use the invitation link to sign in.');
        }

        // Complete sign-in with the link
        if (auth.isSignInWithEmailLink(window.location.href)) {
          await signInWithEmailLink(auth, email, window.location.href);

          // Update client status in Firestore
          const clientRef = doc(db, 'clients', clientId);
          await updateDoc(clientRef, { status: 'pending_kyc' });

          // Clear the email from local storage
          window.localStorage.removeItem('emailForSignIn');

          setIsComplete(true);
        }
      } catch (error) {
        console.error('Error during onboarding:', error);
        setErrorMessage(error.message || 'An error occurred. Please try again.');
      }
    };

    completeSignIn();
  }, [clientId, db]);

  if (isComplete) {
    return <div>Onboarding complete! You can now log in.</div>;
  }

  return (
    <div>
      <h2>Complete Your Onboarding</h2>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {!isComplete && <p>Completing your onboarding. Please wait...</p>}
    </div>
  );
};

export default OnboardingPage;
