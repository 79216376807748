import React from 'react';
import { Footer as StyledFooter, FooterLeft, FooterRight, FooterLink } from './Footer.styles';

const Footer = () => (
  <StyledFooter>
    <FooterLeft>
      <p>&copy; 2024 IBXX. All rights reserved.</p>
    </FooterLeft>
    <FooterRight>
      <FooterLink href="#">Terms of Use</FooterLink>
      <FooterLink href="#">Legal Documents and Notes</FooterLink>
      <FooterLink href="#">Cookies Policy</FooterLink>
      <FooterLink href="#">Privacy Notice</FooterLink>
      <FooterLink href="#">FAQ</FooterLink>
    </FooterRight>
  </StyledFooter>
);

export default Footer;
