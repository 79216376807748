// /src/admin/ClientOnboarding/KYCUpload.styles.js
import styled from 'styled-components';

export const UploadContainer = styled.div`
  background: rgba(0, 0, 0, 0.85);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  margin: 2rem auto;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
`;

export const UploadInput = styled.input`
  width: 100%;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #333;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  transition: border-color 0.3s;

  &:focus {
    border-color: #0069d9;
    outline: none;
  }
`;

export const UploadButton = styled.button`
  background-color: #0069d9;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #004080;
  }
`;

export const UploadMessage = styled.p`
  color: ${({ success }) => (success ? '#28a745' : '#dc3545')};
  font-size: 1rem;
  margin-top: 1.5rem;
  text-align: center;
`;
