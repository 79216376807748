import React from 'react';
import { MainSection as StyledMainSection, GridContainer } from './MainSection.styles';
import AdminDashboard from '../../admin/DataManagement/AdminDashboard';

const MainSection = ({ activeSection }) => {
  return (
    <StyledMainSection>
      {activeSection === 'Overview' && (
        <GridContainer>

        </GridContainer>
      )}
      {activeSection === 'AdminDashboard' && ( // Add case for AdminDashboard
        <AdminDashboard />
      )}
    </StyledMainSection>
  );
};

export default MainSection;
