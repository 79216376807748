import React, { useState } from 'react';
import {
  LanguageDropdown,
  LanguageOption,
  LanguageContainer,
  HeaderLink,
} from '../../../styles/secureLoginStyles';

const languages = [
  { code: 'en', label: 'English' },
  { code: 'fr', label: 'Français' },
  { code: 'de', label: 'Deutsch' },
  { code: 'it', label: 'Italiano' },
  { code: 'es', label: 'Español' },
  { code: 'ru', label: 'Русский' },
  { code: 'jp', label: '日本語' },
];

const ChangeLanguage = ({ onLanguageChange, currentLanguage }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageSelect = (code) => {
    onLanguageChange(code);
    setIsOpen(false);
  };

  return (
    <LanguageContainer>
      <HeaderLink 
        onClick={() => setIsOpen(!isOpen)} 
        className={`no-bullet ${isOpen ? 'active' : ''}`}
      >
        {currentLanguage}
      </HeaderLink>
      {isOpen && (
        <LanguageDropdown>
          {languages.map((lang) => (
            <LanguageOption
              key={lang.code}
              onClick={() => handleLanguageSelect(lang.code)}
            >
              {lang.label}
            </LanguageOption>
          ))}
        </LanguageDropdown>
      )}
    </LanguageContainer>
  );
};

export default ChangeLanguage;
