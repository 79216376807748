// src/components/SecurityAdvice.js
import React from 'react';
import { SecurityAdviceContainer, SecurityAdviceText } from '../../../styles/secureLoginStyles';

const SecurityAdvice = () => {
  return (
    <SecurityAdviceContainer>
      <SecurityAdviceText>
        In order to ensure maximum security, Ian Bloom encourages you to also follow these security recommendations.
        <br /><br />
        <strong>When you use our art solutions</strong>
        <ul>
          <li>Log in to the art platform from your own device and avoid logging in from public wireless (WiFi) networks;</li>
          <li>Do not give out your personal access codes, such as your password or the code to the banking platform token;</li>
          <li>Use different passwords for the different Internet sites you use, and always make sure your passwords contain at least 8-12 characters and a combination of letters, numbers, and special characters;</li>
          <li>Always use the logout option to end your session;</li>
          <li>Clear your browser’s cache after each online session.</li>
        </ul>
        <br />
        <strong>When you receive an email</strong>
        <ul>
          <li>Do not open an email if you do not know the person who sent it or if it appears suspicious. If you have any doubts, delete the message;</li>
          <li>Do not reply to any request for personal information even if the sender claims to be from Ian Bloom;</li>
          <li>Delete junk or chain emails;</li>
          <li>Do not open email attachments from people or sites you do not know.</li>
        </ul>
        <br />
        <strong>When you use your device</strong>
        <ul>
          <li>Update your operating system and other programs on your computer regularly;</li>
          <li>Install the usual security software on your device, such as anti-virus, firewall, and anti-spam programs;</li>
          <li>Remove file and printer sharing options, especially on computers connected to the Internet;</li>
          <li>Consider using encryption technology to protect highly sensitive or confidential information;</li>
          <li>Do not install software or run programs from unknown sources;</li>
          <li>Do not disclose personal, financial, or credit card information to little-known or suspect websites;</li>
          <li>Do not use a computer or a device which cannot be trusted;</li>
          <li>Speak to your contact person at the Ian Bloom if you have any doubts.</li>
        </ul>
        <br />
        Please do not hesitate to call our Client Call Centre if you have any questions or require further information.
      </SecurityAdviceText>
    </SecurityAdviceContainer>
  );
};

export default SecurityAdvice;
