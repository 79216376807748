import React, { useState } from 'react';
import { getAIResponse } from './aiService';
import styled from 'styled-components';
import { Rnd } from 'react-rnd';

const AIRealTimeAssistantContainer = styled.div`
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: rgba(20, 20, 20, 0.95);
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
`;

const ChatBox = styled.div`
  height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 10px;
  border-bottom: 1px solid #444;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1);
  flex: 1; /* This ensures the ChatBox expands to fill available space */
`;

const ChatEntry = styled.div`
  margin-bottom: 10px;
  strong {
    color: #f39c12;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InputField = styled.input`
  width: 80%;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #777;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-family: 'Open Sans', sans-serif;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const SendButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #004085;
  }
`;

const AIRealTimeAssistant = () => {
  const [input, setInput] = useState('');
  const [chat, setChat] = useState([]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userMessage = input.trim();
    if (userMessage) {
      const newChat = [...chat, { role: 'user', message: userMessage }];
      setChat(newChat);
      
      const prompt = `As a developer, I am working on improving my React app. Can you provide feedback or suggestions on the following: "${userMessage}"`;
      
      try {
        const aiResponse = await getAIResponse(prompt);
        setChat([...newChat, { role: 'ai', message: aiResponse }]);
      } catch (error) {
        setChat([...newChat, { role: 'ai', message: 'Sorry, I encountered an error. Please try again.' }]);
      }

      setInput('');
    }
  };

  return (
    <Rnd
      default={{
        x: 100,
        y: 100,
        width: 400,
        height: 500,
      }}
      minWidth={300}
      minHeight={300}
      bounds="window"
      dragHandleClassName="handle"
    >
      <AIRealTimeAssistantContainer>
        <h2 className="handle" style={{ cursor: 'move' }}>AI Real-Time Assistant</h2>
        <ChatBox>
          {chat.map((entry, index) => (
            <ChatEntry key={index}>
              <strong>{entry.role === 'user' ? 'You' : 'AI'}:</strong> {entry.message}
            </ChatEntry>
          ))}
        </ChatBox>
        <form onSubmit={handleSubmit}>
          <InputContainer>
            <InputField
              type="text"
              value={input}
              onChange={handleInputChange}
              placeholder="Ask anything about improving the app..."
            />
            <SendButton type="submit">Send</SendButton>
          </InputContainer>
        </form>
      </AIRealTimeAssistantContainer>
    </Rnd>
  );
};

export default AIRealTimeAssistant;
